<template>
    <div class="main">
        <div class="header">
            <div class="money">
                <span class="currency">Rp</span>{{ amount }}
            </div>
            <p class="desc">OrderlD:{{ orderId }}</p>
        </div>
        <div class="content" v-if="tabIndex !== null">
            <div class="bank-content">
                <div class="tab-warp">

                    <!-- <div class="tab-top">
                        <div class="tab-item" :class="{ 'active': tabIndex == 0 }" @click="changeTabIndex(0)">
                            Bank Transfer
                        </div>
                        <div class="tab-item" :class="{ 'active': tabIndex == 1 }" @click="changeTabIndex(1)">
                            QRIS
                        </div>
                    </div>
                    -->

                    <div class="tab-content">
                        <div class="tab-content-item" v-if="tabIndex == 0">
                            <div class="content-title">Bank Transfer</div>
                            <div class="content-img-list">
                                <div class="content-img-item" @click="toBank(item)" v-for="(item, index) in bankList "
                                    v-bind:key="index">
                                    <img :src=item.img />
                                </div>
                            </div>
                        </div>
                        <div class="tab-content-item" v-if="tabIndex == 1">
                            <div class="content-title">QRIS</div>
                            <div class="content-img-list">
                                <div class="content-img-item" @click="toQris">
                                    <img src="../../../assets/images/qrcode_logo.jpg" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="footer">


            <hr />

            <!-- <div class="paragraph">
                <p>Tips:</p>
                <p>lf your payment is successful and your account has not increased the amountplease prowide your order
                    number
                    and
                    payment voucher to contact us assoon as possilble.</p>
            </div> -->
            <div class="mail">
                <p>
                    <!-- <i class="envelope"></i> -->
                    <!-- {{ $config.SYS_AIOPAY_EMAIL }} &nbsp;&nbsp; -->
                    Powered by pay.aio-pay.com ver:{{ $config.software_version }}</p>
            </div>
            <OrderInfo :orderId=orderId></OrderInfo>


            <!-- <div class="make" @click="copyTransactionId(resData.channelOrderNo)" v-if="mateType === 'virtual'">
                <button>Salin</button>
            </div> -->

        </div>
    </div>
</template>
<script>
import _validate from "@/utils/validate";
import * as _cookie from '@/utils/cookie'
import {
    getPayOrderInfoByPayOrderId,
    submitUnifiedOrder
} from '@/api/api'

import OrderInfo from "./components/OrderInfo.vue";
export default {
    components: {
        OrderInfo
    },
    data() {
        return {
            amount: "0",
            orderId: '',
            tabIndex: null,
            virAccountNo: "",
            bankList: [
                {
                    name: "BRI",
                    bank_code: "bri",
                    img: "/static/assets/images/indonesia/va_bri.png"
                },
                {
                    name: "permate",
                    bank_code: "permata",
                    img: "/static/assets/images/indonesia/pc20220404548-557.png"
                },
                {
                    name: "BNI",
                    bank_code: "bni",
                    img: "/static/assets/images/indonesia/va_bni.png"
                },
                {
                    name: "CIMB",
                    bank_code: "cimb",
                    img: "/static/assets/images/indonesia/va_cimb.png"
                }
            ],
            wayCode: null,
            qrCodeUrl: "",
        }
    },
    created() {
        this.orderId = this.$route.query.tn;
        this.getPaymentOrderData();
    },
    mounted() {

    },
    methods: {
        getPaymentOrderData() {
            const _this = this;

            getPayOrderInfoByPayOrderId(this.orderId).then(res => {
                
                _this.amount = _this.$tools.formatNumber(res.amt)
                _this.wayCode = res.wayCode

                // _this.wayCode = "MIKA_GVAa"
                if (_this.wayCode === 'MIKA_GVA') {
                    _this.parseBankRecord(res);
                    _this.tabIndex = 0;
                } else {
                    _this.tabIndex = 1;
                }

            })

        },
        parseBankRecord(record) { 
            const _this = this;
            if (!_validate.empty(record.bank) && record.bank) { 
                _this.bankList.forEach(element => { 
                    if (element.bank_code.toLowerCase() === record.bank.toLowerCase()) { 
                        //临时存储虚拟账户
                        this.virAccountNo = record.channelOrderNo;
                        if (_validate.empty(this.virAccountNo)) { 
                            return;
                        }
                        //临时存储内容
                        _cookie.setData({
                            virAccountNo: this.virAccountNo,
                            orderId: this.orderId,
                            amt: this.amount,
                            bank: element
                        });

                        setTimeout(() => {
                            location.href = '/cashier/bankdetail'
                        })
                        return;
                    }
                })
            }
        },
        copyNum() {
            //拷贝数字
        },
        changeTabIndex(index) {
            this.tabIndex = index;
        },
        toQris() {
            const _this = this;
            submitUnifiedOrder(this.orderId).then(res => {
                _this.wayCode = res.wayCode
                _this.qrCodeUrl = res.qrCodeUrl;
                console.log(`output->_this.qrCodeUrl`,_this.qrCodeUrl)
                if (_validate.empty(_this.qrCodeUrl)) {
                    alert('QrUrl Error, Silakan hubungi platform');
                    return;
                }

                //临时存储内容
                _cookie.setData({
                    qrCodeUrl: this.qrCodeUrl,
                    orderId: this.orderId,
                    amt: this.amount,
                });

                setTimeout(() => {
                    location.href = '/cashier/qrcodedetail'
                }, 200)

                //临时存储虚拟账户
            }).catch(error => {
                alert('permintaan dibatalkan[' + error + ']');
            })
            //请求二维码内容

            // location.href = '/cashier/qrcodedetail'
        },
        toBank(record) {
            if (this.amount <= 0) {
                return;
            }
            //临时存储虚拟账户
            // this.virAccountNo = "4041910113217624";
            // //临时存储内容
            // _cookie.setData({
            //     virAccountNo: this.virAccountNo,
            //     orderId: this.orderId,
            //     amt: this.amount,
            //     bank: record
            // });

            // setTimeout(() => { 
            //     location.href = '/cashier/bankdetail'
            // }, 200)
            submitUnifiedOrder(this.orderId, { bank: record.bank_code }).then(res => {
                console.log(`output->res`, res)
                //临时存储虚拟账户
                this.virAccountNo = res.virAccountNo;
                
                if (_validate.empty(this.virAccountNo)) { 
                    throw new Error("Va gagal");
                }
                //临时存储内容
                _cookie.setData({
                    virAccountNo: this.virAccountNo,
                    orderId: this.orderId,
                    amt: this.amount,
                    bank: record
                });

                setTimeout(() => {
                    location.href = '/cashier/bankdetail'
                }, 200)
            }).catch(error => {
                alert('permintaan dibatalkan[' + error + ']');
            })

        }
    }

}
</script>
<style>
html,
body {
    padding: 0 !important;
    margin: 0 !important;
    background-color: #eee;
}
</style>
<style scoped>
* {
    font-family: '微软雅黑';

}

.main {
    min-height: 100vh;
}

.header {
    /* Safari 5.1 - 6.0 */
    background: -webkit-linear-gradient(to right, #367af7, #76a6ff);
    /* Opera 11.1 - 12.0 */
    background: -o-linear-gradient(to right, #367af7, #76a6ff);
    /* Firefox 3.6 - 15 */
    background: -moz-linear-gradient(to right, #367af7, #76a6ff);
    background: linear-gradient(to right, #367af7, #76a6ff);
    background-color: #367af7;
    height: 4rem;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.header .content {
    margin-top: .4rem;
    padding: 0 .4rem;
}

.header .money {
    color: #fff;
    font-size: 102px;
    padding-left: .4rem;
    font-family: '黑体';
    font-weight: 600;
    margin-top: .6rem;
}

.header .money .currency {
    font-size: 62px;
}

.header .desc {
    font-size: 24px;
    color: #d9e4ff;
    padding-left: 0.4rem;
    margin: 0.8rem 0 0 0;
}

.content {
    margin-top: .4rem;
    padding: 0 .4rem;
}


.content>p.payUsing {
    padding: 0;
    margin: 0;
    font-size: .6rem;
    text-align: left;
    color: #939393;
}

.bank-content {
    width: 100%;
    margin: 0.4rem auto 0 auto;
    background: #fff;
    border-radius: 0.186667rem;
    padding: 0 0.4rem 0.4rem 0.4rem;
    box-sizing: border-box;
}

.bank-detail {
    background: #FAFAFA;
    padding: .4rem .6rem;
    box-sizing: border-box;
}

.bank-detail p {
    margin: 0;
}

.bank-detail p.t1 {
    background: #FFF5E3;
    color: #b49d6e;
    font-size: .34rem;
    padding: .2rem 0;
}

.bank-detail>.icon {
    width: calc(100% - .8rem);
    background: #fff;
    margin: .4rem auto 0 auto;
    border-radius: 0.186667rem;
    padding: .4rem;
    box-sizing: border-box;
}

.bank-detail>.icon>img {
    width: 100%;
}

.bank-detail .sub_t1 {
    color: #858C91;
    font-size: .32rem;
    padding: .2rem 0;
    margin-top: .2rem;
}

.bank-detail .qrcode {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: 0.4rem;
    font-weight: bold;
    text-align: center;
    margin-top: .4rem;
    padding: 0 .4rem;
}


.bank-detail .qrcode .qr {
    width: 70%;
}

.bank-detail .qrcode .logo {
    width: 30%;
}

.bank-detail .qrcode .qr img {
    width: 100%;
}

.bank-detail .qrcode .logo img {
    width: 100%;
}


.bank-detail .bank_name {
    text-align: center;
    font-size: .4rem;
    margin-top: .8rem;


}

.bank-detail .bank_name p.rp {
    font-weight: bold;
}

.bank-detail .status_pam button {
    outline: none;
    border: none;
    padding: 0 .4rem;
    height: .9rem;
    line-height: .9rem;
    border-radius: 0.186667rem;
    background: #74BAFF;
    color: #fff;
}

.tab-warp {
    width: 100%;

}

.tab-top {
    width: 100%;
    border-bottom: 1px solid #EEEEEE;
    display: flex;
    flex-direction: row;

}

.tab-item {
    font-size: 0.36rem;
    width: 100%;
    white-space: nowrap;
    padding: .38rem 0;
}

.tab-item.active {
    color: #75BAFF;
    border-bottom: 6px solid #75BAFF;
    margin-bottom: -3px;
}

.tab-content {
    width: 100%;
}

.tab-content .tab-content-item {
    width: 100%;
    padding: 0.4rem 0.6rem;
    box-sizing: border-box;
}


.tab-content .tab-content-item .content-title {
    font-size: .5rem;
    white-space: nowrap;
    text-align: left;
    margin-bottom: .4rem;
}

.tab-content .tab-content-item .content-sub {
    font-size: .34rem;
    text-align: left;
    color: #9b9b9b;
}

.tab-content .tab-content-item .content-sub p {
    margin-top: .28rem;
    margin-bottom: 0;
}

.tab-content .tab-content-item .content-sub .txt-blue {
    color: #75BAFF;
}

.tab-content .tab-content-item .content-sub .txt-grey {
    color: #7a7a7b;
    font-weight: bold;
}

.content-img-list {
    width: 100%;
}

.content-img-item {
    width: 100%;
    padding: .4rem .6rem;
    box-sizing: border-box;
    border: 1px solid #e4e7ed;
    margin-bottom: 0.4rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.content-img-item img {
    max-width: 100%;
    max-height: 100%;
}







/* -----------------------footer------------------------ */



.footer {
    width: 100%;
    font-size: .4rem;
    margin-top: 2rem;
    padding-bottom: 200px;
}

.footer .footLogo {
    text-align: center;
}

.footer .footLogo p {
    margin: 0;
    color: #5d5d5d;
    font-size: .2rem;

}

.footer .footLogo img {
    width: 1.0rem;
    height: auto;
    margin-top: .1rem;
}

.footer .make {
    position: fixed;
    bottom: 0;
    left: 50%;
    transform: translatex(-50%);
    width: 100%;
    background-color: #eee;
}

.footer .make>button {
    /* Safari 5.1 - 6.0 */
    background: -webkit-linear-gradient(to right, #367af7, #76a6ff);
    /* Opera 11.1 - 12.0 */
    background: -o-linear-gradient(to right, #367af7, #76a6ff);
    /* Firefox 3.6 - 15 */
    background: -moz-linear-gradient(to right, #367af7, #76a6ff);
    background: linear-gradient(to right, #367af7, #76a6ff);
    background-color: #367af7;
    color: #fff;
    font-size: .6rem;
    border: none;
    border-radius: .6rem;
    display: block;
    width: 90%;
    height: 1.4rem;
    margin: .6rem auto;
}

.footer .paragraph {
    text-align: left;
    padding: 0 .6rem;
    font-size: .2rem;
    color: #939393;
}

.footer .mail {
    text-align: left;
    padding: 0 0.6rem;
    font-size: .2rem;
}

.footer hr {
    margin: .2rem .6rem;
    border: 1px solid #fff;
}

.footer .envelope::after {
    font-family: fontAwesome;
    content: "\f0e0";
    font-weight: normal;
    font-style: normal;
    font-size: .4rem;
}
</style>